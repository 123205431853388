<template>
  <div>
    <Head />
    <div class="wrapper" v-if="HomeData && HomeData.Public_Category">
      <section class="main">
        <div class="container">
          <div class="title">
            <h1>{{ HomeData.Public_Category.Category.CategoryName }}</h1>
          </div>
          <ul class="list">
            <li v-for="Post in HomeData.Public_Category.Posts">
              <a href="javascript:void(0)" @click="gotoPost(Post)">
                <img v-if="Post.Image" :src="HomeData.SiteUrl + Post.Image" alt="">
                <img v-if="!Post.Image" src="img/img_1.jpg" alt="">
                <p>{{ Post.Title }}</p>
              </a>
            </li>
          </ul>
          <div class="btn_box">
            <button @click="gotoCategoryByCId(HomeData.Public_Category.Category.CId)">MORE</button>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->
    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "Home",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      HomeData: {},
      local: "zh_tw",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Home",
        {
          params: {
            lang: $cookies.get("is_site_lang")
          },
        }
      )
      .then((response) => {
        this.HomeData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        this.$router.push({
          name: "Post",
          params: { PId: Post.PId },
        });
      }
    },
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
  },
};
</script>

