<template>
  <div>
    <Head />
    <div class="wrapper inside_pages" v-if="PostData">
      <section class="main">
        <div class="container">
          <div class="list_box" v-if="PostData.Post_Left_Menu">
            <ul class="list_1">
              <li v-for="(MenuData, index) in PostData.Post_Left_Menu">
                <a v-if="!MenuData.SubMenus" href="javascript:void(0)">
                  <p>{{ MenuData.Menu.Title }}</p>
                </a>
                <a v-if="MenuData.SubMenus" href="javascript:void(0)">
                  <p>{{ MenuData.Menu.Title }}</p>
                </a>
                <ul v-if="MenuData.SubMenus" class="list_2">
                  <li v-for="(SubMenuData, index) in MenuData.SubMenus">
                    <a v-if="!SubMenuData.SubMenus" href="javascript:void(0)" @click="goto(SubMenuData.Menu)">
                      <p>{{ SubMenuData.Menu.Title }}</p>
                    </a>
                    <a v-if="SubMenuData.SubMenus" href="javascript:void(0)">
                      <p>{{ SubMenuData.Menu.Title }}</p>
                    </a>
                    <ul v-if="SubMenuData.SubMenus" class="list_3">
                      <li v-for="(SubSubMenuData, index) in SubMenuData.SubMenus">
                        <a href="javascript:void(0)" @click="goto(SubSubMenuData)">
                          <p>{{ SubSubMenuData.Title }}</p>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="text_box">
            <div class="title">
              <h1>{{ PostData.Post.Title }}</h1>
            </div>
            <img v-if="PostData.Post.Image" :src="PostData.SiteUrl + PostData.Post.Image" alt="">
            <div class="text" v-html="PostData.Post.Content">
            </div>
          </div>
        </div>
      </section>
    </div><!-- wrapper -->
    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "Post",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      PostData: null,
      Attachments: null,
      Slideshows: null,
      local: "zh_tw",
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
        {
          params: { PId: to.params.PId, CId: to.params.CId },
        }
      )
      .then((response) => {
        this.PostData = response.data;
        if (this.PostData.Post.Attachment != "[]") {
          this.Attachments = JSON.parse(this.PostData.Post.Attachment);
        } else {
          this.Attachments = null;
        }
        if (this.PostData.Post.Slideshow != "[]") {
          this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
        } else {
          this.Slideshows = null;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Post",
        {
          params: { PId: this.$route.params.PId, CId: this.$route.params.CId },
        }
      )
      .then((response) => {
        this.PostData = response.data;
        if (this.PostData.Post.Attachment != "[]") {
          this.Attachments = JSON.parse(this.PostData.Post.Attachment);
        }
        if (this.PostData.Post.Slideshow != "[]") {
          this.Slideshows = JSON.parse(this.PostData.Post.Slideshow);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
    $(".inside_pages .main .list_box ul > li > a").off();
    $(".inside_pages .main .list_box ul > li > a").click(function () {
      // 按鈕
      $(this).siblings("ul").find("a").removeClass("add");
      $(this).parent("li").siblings().find("a").removeClass("add");
      $(this).toggleClass("add");
      // ul縮放
      $(this).siblings("ul").find("ul").slideUp();
      $(this).parent("li").siblings().find("ul").slideUp();
      $(this).next("").slideToggle();
    });
  },
  methods: {
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    goto: function (MenuData) {
      if (MenuData.Type == 1) {
        this.gotoCategoryByCId(MenuData.CId);
      } else if (MenuData.Type == 2) {
        this.gotoPostByPId(MenuData.PId);
      } else if (MenuData.Type == 3) {
        this.gotoUrl(MenuData.Url, MenuData.Blank);
      }
    },
  },
};
</script>
  
  