import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Category from '../views/Category.vue'
import Post from '../views/Post.vue'
import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Category/:CId',
    name: 'Category',
    component: Category
  },
  {
    path: '/Post/:PId/:CId?',
    name: 'Post',
    component: Post
  },
  {
    path: '/Search/:Keyword',
    name: 'Search',
    component: Search
  },
]

const router = new VueRouter({
  routes
})

export default router

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}