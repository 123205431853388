<template>
    <!-- 尾 -->
    <footer>
        <div class="container" v-if="FooterData" v-html="FooterData.Footer_Text">
        </div>
        <div class="bottom"></div>
    </footer>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    name: "Footer",
    data() {
        return {
            FooterData: null,
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/Footer",
                {
                    params: { lang: $cookies.get("is_site_lang") },
                }
            )
            .then((response) => {
                this.FooterData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () {
    },
};
</script>
  