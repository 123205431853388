<template>
    <!-- 頭 -->
    <nav class="navbar navbar-expand-lg">
        <div class="container">
            <router-link to="/" class="navbar-brand">
                <img src="img/logo.png" alt="">
            </router-link>
            <div class="search_box">
                <input id="Keyword" v-model="Keyword" type="text" placeholder="請輸入關鍵字" v-on:keyup.enter="Search()">
                <button @click="Search()">搜尋</button>
            </div>
        </div>
        <div class="container-fluid" v-if="HeadData && HeadData.Header_Menu">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="line1"></span>
                <span class="line2"></span>
                <span class="line3"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!-- 第一層 -->
                <ul class="navbar-nav menu">
                    <li class="nav-item" v-for="(MenuData, index) in HeadData.Header_Menu">
                        <!-- 一層 -->
                        <a v-if="!MenuData.SubMenus" class="nav-link" href="javascript:void(0)" :title="MenuData.Menu.Title"
                            @click="goto(MenuData.Menu)">{{ MenuData.Menu.Title }}</a>
                        <!-- 二層 + 三層 -->
                        <a v-if="MenuData.SubMenus" class="nav-link dropdown-toggle" href="javascript:void(0)" title=""
                            role="button" data-toggle="dropdown" aria-expanded="false">{{ MenuData.Menu.Title }}</a>
                        <div v-if="MenuData.SubMenus" class="dropdown-menu " aria-labelledby="dropdown-submenu-1">
                            <div class="container">
                                <!-- 三層-1 -->
                                <div class="aa" v-for="(SubMenuData, index_sub) in MenuData.SubMenus">
                                    <a v-if="!SubMenuData.SubMenus" class="dropdown-item" href="javascript:void(0)"
                                        :title="SubMenuData.Menu.Title" @click="goto(SubMenuData.Menu)">{{
                                            SubMenuData.Menu.Title }}</a>
                                    <a v-if="SubMenuData.SubMenus" class="dropdown-item"
                                        href="javascript:void(0)">
                                        {{ SubMenuData.Menu.Title }}
                                    </a>
                                    <div class="bb" v-if="SubMenuData.SubMenus">
                                        <a v-for="(SubSubMenuData, index_sub_sub) in SubMenuData.SubMenus"
                                            class="dropdown-item" href="javascript:void(0)" :title="SubSubMenuData.Title"
                                            @click="goto(SubSubMenuData)">{{ SubSubMenuData.Title }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
  
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
    zh_tw: {
    },
    en: {
    },
};

const i18n = new VueI18n({
    locale: "zh_tw",
    messages,
});

export default {
    name: "Head",
    components: {
    },
    data() {
        return {
            HeadData: null,
            Keyword: '',
            local: "zh_tw",
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/Head",
                {
                    params: {
                        lang: $cookies.get("is_site_lang")
                    },
                }
            )
            .then((response) => {
                this.HeadData = response.data;
                // 漢堡紐
                $("nav .navbar-toggler").click(function () {
                    $("nav .navbar-toggler span").toggleClass("add");
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () {
        if ($cookies.isKey("is_site_lang")) {
            i18n.locale = $cookies.get("is_site_lang");
            this.local = $cookies.get("is_site_lang");
        } else {
            i18n.locale = $cookies.get("is_site_lang");
            this.local = "zh_tw";
        }
    },
    methods: {
        gotoCategoryByCId: function (CId) {
            this.$router.push({
                name: "Category",
                params: { CId: CId },
            });
        },
        gotoPostByPId: function (PId) {
            this.$router.push({
                name: "Post",
                params: { PId: PId },
            });
        },
        gotoUrl: function (Url, Blank) {
            if (Blank) {
                window.open(Url, "_blank");
            } else {
                location.href = Url;
            }
        },
        goto: function (MenuData) {
            if (MenuData.Type == 1) {
                this.gotoCategoryByCId(MenuData.CId);
            } else if (MenuData.Type == 2) {
                this.gotoPostByPId(MenuData.PId);
            } else if (MenuData.Type == 3) {
                this.gotoUrl(MenuData.Url, MenuData.Blank);
            }
        },
        Search() {
            if (this.Keyword) {
                this.$router.push({
                    name: "Search",
                    params: { Keyword: this.Keyword },
                });
            }
        }
    },
};
</script>